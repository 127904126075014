<template>
  <v-card>
    <v-container
      fluid
    >
      <v-row>
        <v-col
          md="12"
        >
          <v-row>
            <v-col
              cols="8"
              sm="8"
              md="3"
            >
              <v-btn
                disabled
              >
                Показывать элементов:
              </v-btn>
            </v-col>
            <v-col
              cols="3"
              sm="4"
              md="1"
            >
              <v-select
                v-model="pagination.limit"
                :items="[
                  5,
                  10,
                  15,
                  20
                ]"
                @change="setAmount"
              />
            </v-col>
            <v-col
              sm="12"
              md="3"
            >
              <v-btn
                disabled
                style="
                          background-color: transparent !important;
                          color: white !important;
                          font-size: 13px;
                        "
              >
                Показано: {{ pagination.from }} - {{ pagination.to }} из {{ pagination.totalItems }}
              </v-btn>
            </v-col>
            <v-col
              cols="4"
              sm="4"
              md="2"
            >
              <v-btn
                :disabled="pagination.page == 1"
                @click="prevPage"
              >
                <v-icon>
                  mdi-arrow-left-bold
                </v-icon>
              </v-btn>
            </v-col>
            <v-col
              cols="4"
              sm="4"
              md="1"
            >
              <v-text-field
                v-model="pagination.page"
                type="number"
                min="1"
              />
            </v-col>
            <v-col
              cols="4"
              sm="4"
              md="2"
            >
              <v-btn
                @click="nextPage"
              >
                <v-icon>
                  mdi-arrow-right-bold
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
  export default {
    name: 'Pagination',
    data () {
      return {
        pagination: {
          direction: 'desc',
          page: 1,
          limit: 10, // -1 for All
          sortBy: 'id',
          totalItems: 0,
          from: 0,
          to: 0,
        },
      }
    },
    methods: {
      nextPage () {
        this.pagination.page += 1
        this.$emit('pagination', this.pagination)
      },
      prevPage () {
        this.pagination.page -= 1
        this.$emit('pagination', this.pagination)
      },
      setAmount () {
        this.pagination.page = 1
        this.$emit('pagination', this.pagination)
      },
    },
  }
</script>

<style scoped>

</style>
