<template>
  <v-container
    id="regular-tables-view"
    fluid
    tag="section"
  >
    <view-intro
      heading="Объявления"
      link="components/simple-tables"
    />

    <material-card
      icon="mdi-clipboard-text"
      icon-small
      title="Объявления"
      color="accent"
    >
      <v-simple-table>
        <thead>
          <tr>
            <th class="primary--text">
              ID
            </th>
            <th class="primary--text">
              Имя
            </th>
            <th class="primary--text">
              Описание
            </th>
            <th class="primary--text">
              Категория
            </th>
            <th class="primary--text">
              Пользователь
            </th>
            <th class="text-right primary--text" />
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(announcement, index) in announcements"
            :key="index"
          >
            <td>{{ announcement.id }}</td>
            <td>{{ announcement.name }}</td>
            <td>{{ announcement.description }}</td>
            <td>{{ announcement.category_id }}</td>
            <td>{{ announcement.user_id }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </material-card>

    <pagination
      @pagination="updatePagination"
    />
  </v-container>
</template>

<script>
  import Pagination from '@/components/Pagination'

  export default {
    name: 'RegularTablesView',
    components: { Pagination },
    data () {
      return {
        announcements: [],
        pagination: {},
      }
    },
    mounted () {
      this.getData()
    },
    methods: {
      getData () {
        this.$http
          .get('announcements/paginate', {
            params: this.pagination,
          })
          .then(response => {
            this.announcements = response.data.data
          })
      },
      updatePagination (event) {
        this.pagination = event
        this.getData()
      },
    },
  }
</script>
