<template>
  <v-container
    id="regular-tables-view"
    fluid
    tag="section"
  >
    <view-intro
      heading="Пользователи"
      link="components/simple-tables"
    />

    <material-card
      icon="mdi-clipboard-text"
      icon-small
      title="Пользователи"
      color="accent"
    >
      <v-simple-table>
        <thead>
          <tr>
            <th class="primary--text">
              ID
            </th>
            <th class="primary--text">
              Имя
            </th>
            <th class="primary--text">
              E-Mail
            </th>
            <th class="primary--text">
              Телефон
            </th>
            <th class="text-right primary--text" />
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(user, index) in users"
            :key="index"
          >
            <td>{{ user.id }}</td>
            <td>{{ user.name }}</td>
            <td>{{ user.email }}</td>
            <td>{{ user.phone }}</td>
            <td class="text-right">
              <v-btn>
                Объявления
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </material-card>

    <pagination
      @pagination="updatePagination"
    />
  </v-container>
</template>

<script>
  import Pagination from '@/components/Pagination'

  export default {
    name: 'RegularTablesView',
    components: { Pagination },
    data () {
      return {
        users: [],
        pagination: {},
      }
    },
    mounted () {
      this.getData()
    },
    methods: {
      getData () {
        this.$http
          .get('users/paginate', {
            params: this.pagination,
          })
          .then(response => {
            this.users = response.data.data
          })
      },
      updatePagination (event) {
        this.pagination = event
        this.getData()
      },
    },
  }
</script>
